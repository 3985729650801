import React from 'react';
import styled from 'styled-components';

import { useRouter } from 'apis/history';
import { mobile, useQuery } from 'styles/breakpoints';
import { almostWhite, blue, blue70 } from 'styles/colors';
import {
  Circle,
  Container,
  H2,
  Image,
  PrimaryButton,
  Regular,
  Small,
  Svg,
  TextWrapper,
} from 'components';

const LivingWithHeartIssues: React.FC = React.memo(() => {
  const { isMobile } = useQuery();
  const { goToQuiz } = useRouter();

  return (
    <Background>
      <StyledContainer>
        <div>
          <Svg
            src="icons/leaves_1"
            width="52"
            height="36"
            viewBox="0 0 52 36"
            fill="none"
          />
          <TextWrapper
            maxWidth="14.75rem"
            margin={isMobile ? '0.75rem 0 0.875rem' : '0.75rem 0 1.25rem'}
          >
            <H2 color={blue}>
              Living with
              <br />
              hearth issues
            </H2>
          </TextWrapper>
          <TextWrapper maxWidth="20.875rem" margin="0 0 2.125rem">
            <Regular color={blue70}>
              Learn how to avoid common treatment mistakes and find what simple
              lifestyle changes you can make to feel great again. It doesn’t
              take a big leap; just a small step on the right path will do the
              trick.
            </Regular>
          </TextWrapper>
          <PrimaryButton
            maxWidth={isMobile ? '100%' : '10.5rem'}
            onClick={goToQuiz}
          >
            TAKE THE QUIZ
          </PrimaryButton>
        </div>
        <RightColumn>
          <Image src="gel" />
          {!isMobile && (
            <StyledCircle size={7.125} color={blue} position="absolute">
              <Small fontSize="0.75rem" textAlign="center" color={almostWhite}>
                IMPROVED
                <br />
                HEART
                <br />
                FORMULA
              </Small>
            </StyledCircle>
          )}
        </RightColumn>
      </StyledContainer>
    </Background>
  );
});

LivingWithHeartIssues.displayName = 'LivingWithHeartIssues';

export default LivingWithHeartIssues;

const Background = styled.div`
  padding: 6.875rem 0;
  background: ${almostWhite};

  @media ${mobile} {
    padding: 1.5rem 0 3.75rem;
  }
`;
const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 6.5rem;

  @media ${mobile} {
    flex-direction: column-reverse;
    padding: 0 1.5rem;
  }
`;
const RightColumn = styled.div`
  position: relative;
  max-width: 30.625rem;
  width: 100%;

  @media ${mobile} {
    padding-bottom: 2.5rem;
  }
`;
const StyledCircle = styled(Circle)`
  top: 3.625rem;
  right: 0;
  transform: translateX(50%) rotate(14deg);
`;
